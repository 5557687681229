<template>
    <v-container fill-height fluid>
        <v-card class="pa-2">
            <v-row>
                <v-col>
                    <v-row><v-col>Username: {{ user.username }}</v-col></v-row>
                    <v-row><v-col>Email: {{ user.user_email }}</v-col></v-row>
                    <v-row><v-col>Logout and reset password: <v-btn @click="logout">Logout</v-btn></v-col></v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
    import { useAuth0 } from '@auth0/auth0-vue';
    export default {
        name: 'UserProfile',
        setup() {
            const {logout, user, isAuthenticated, idTokenClaims } = useAuth0();
            return {
                logout: () => {
                    logout({ logoutParams: { returnTo: window.location.origin } });
                },
                user,
                isAuthenticated,
                idTokenClaims
            }
        }
    }
</script>