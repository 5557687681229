<template>
  <v-container fill-height fluid>
      <v-row align="start"
      justify="center">
          <v-col>
                  <v-card class="pl-1" title="myHana Light" text="Participate in discussion group and join careteams.">
                      <template v-slot:subtitle>
                        <h3>$0/month</h3>
                      </template>
                      <v-card-actions>
                          <v-btn @click="getCheckOutURL('freeTier')">Subscribe</v-btn>
                      </v-card-actions>
                      </v-card>
          </v-col>
          <v-col>
                  <v-card class="pl-1" title="myHana Plus" text="In addition, gain access to exclusive training resources, classes, and careteam tools.">
                      <template v-slot:subtitle>
                        <h3>$25/month</h3>
                      </template>
                      <v-card-actions>
                          <v-btn @click="getCheckOutURL('paidTier')">Subscribe</v-btn>
                      </v-card-actions>
                      </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
  export default {
    name: "NewSubscription",
    methods: {
      //Get a link to checkout usign the id of the subscription selected and the customer's id
      async getCheckOutURL(product) {
        var session_token = this.getSessionToken('session_token');
        var state = this.getSessionToken('state');
        await fetch('https://xv127qh5hb.execute-api.us-east-1.amazonaws.com/prod/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'product': product,
            'session_token': session_token,
            'state': state,
            'type': 'checkout'
          })
        }).then(response =>
          //Redirect to the link retrieved from the backend
          response.text()
        ).then(data =>
          window.location.replace(data)
        );
      },
      //Get the session token associated with this account creation flow
      getSessionToken(param) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if (pair[0] == param) {
            console.log(pair[1]);
            return pair[1];
          }
        }
        return '';//in this case, check notify the user that the session is messed up
      }
    }
  }
</script>