<template>
  <v-app>
    <v-main>
      <v-row id="navbar">
        <v-app-bar dense dark class="primary">
          <v-app-bar-title>myHana Account</v-app-bar-title>
          <v-btn class="ma-1" variant="text" v-if="isAuthenticated" @click="route('/')">myHana Apps</v-btn>
          <v-btn class="ma-1" variant="text" v-if="isAuthenticated" @click="route('/profile')">Profile</v-btn>
          <v-btn class="ma-1" variant="text" v-if="isAuthenticated" @click="getBillingURL">Billing</v-btn>
        </v-app-bar>
      </v-row>
      <!-- router-view displays the selected content, is managed by /router -->
      <v-col class="pt-8">
        <router-view/> 
      </v-col>
    </v-main>
  </v-app>
</template>
<script>
  import { useAuth0 } from '@auth0/auth0-vue';
  export default {
    name: "App",
    setup() {
      const { loginWithRedirect, user, isAuthenticated, idTokenClaims } = useAuth0();
      return {
        login: () => {
          loginWithRedirect();
        },
        user,
        idTokenClaims,
        isAuthenticated
      };
    },
    methods: {
      //Get a link to checkout usign the id of the subscription selected and the customer's id
      async getBillingURL() {
        await fetch('https://xv127qh5hb.execute-api.us-east-1.amazonaws.com/prod/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'product': '',
            'session_token': this.idTokenClaims.stripe_customer_id,
            'state': '',
            'type': 'billing'
          })
        }).then(response =>
          //Redirect to the link retrieved from the backend
          response.text()
        ).then(data =>
          window.location.replace(data)
        );
      },
      //Route to the specified path in the router
      route(nextPath) {
        this.$router.push({ path: nextPath})
      }
    } 
  };
</script>
<!-- <template>
  <div>
    <h2>User Profile</h2>
    <button @click="login">Log in</button>
    <pre v-if="isAuthenticated">
        <code>{{ user }}</code>
        <code>{{ idTokenClaims }}</code>
      </pre>
  </div>
</template> -->
<!-- <script>
  import { useAuth0 } from '@auth0/auth0-vue';
  export default {
    setup() {
      const { loginWithRedirect, user, isAuthenticated, idTokenClaims } = useAuth0();
      return {
        login: () => {
          loginWithRedirect();
        },
        user,
        idTokenClaims,
        isAuthenticated
      };
    }
  };
</script> -->
<!-- <template>
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>
<script>
import HelloWorld from './components/HelloWorld.vue'
export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style> -->