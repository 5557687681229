import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import { createAuth0, authGuard } from '@auth0/auth0-vue';

import App from './App.vue';

import NewSubscription from './views/NewSubscription.vue';
import UserApplications from './views/UserApplications.vue';
import UserProfile from './views/UserProfile.vue';

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

const app = createApp(App);

app.use(createRouter({
  routes: [
    {
      path: '/subscribe',
      name: 'subscribe',
      component: NewSubscription
    },
    {
      path: '/profile',
      name: 'profile',
      component: UserProfile,
      beforeEnter: authGuard
    },
    {
      path: '/',
      name: 'applications',
      component: UserApplications,
      beforeEnter: authGuard
    }
  ],
  history: createWebHashHistory()
}));

app.use(createAuth0({
  domain: "profile.myhana.io",
  clientId: "9LDLRR9PY1lvl9BxtjZhjpoByFq7ZON1",
  authorizationParams: {
    redirect_uri: window.location.origin
  }
}));

app.use(vuetify);

app.mount('#app');