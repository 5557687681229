<template>
    <v-container fill-height fluid>
        <v-row align="start"
        justify="center">
            <v-col>
                    <v-card class="pl-1" title="Community" text="Join classes, compare notes, and build friendships with other caregivers.">
                        <v-card-actions>
                            <v-btn variant="tonal" @click="redirectUser('https://profile.myhana.io/authorize?client_id=MqfqLyXdKMT1uNU6meAoL67Hq1bSfCXE&redirect_uri=https%3A%2F%2Fcircle.myhana.io%2Foauth2%2Fcallback&response_type=code&scope=openid')">Launch Community</v-btn>
                        </v-card-actions>
                        </v-card>
            </v-col>
            <v-col>
                    <v-card class="pl-1" title="Caregiving Management Tools" text="Store and manage files, calendar events, and notes with your care team.">
                        <v-card-actions>
                            <v-btn variant="tonal" @click="redirectUser('https://cm.myhana.io')">Launch Caregiving Management Tools</v-btn>
                        </v-card-actions>
                        </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: 'UserApplications',
        methods: {
            redirectUser(url) {
                window.location.replace(url)
            }
        }
    }
</script>